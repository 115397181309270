import { FC } from 'react'
import { Panel } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'

import CTACard from '../../CTACard'
import useLoginModal from '../../../services/useLoginModal'
import SafeTranslate from '../../common/SafeTranslate'
import { useAuth } from '../../../services/useAuth'
import useUrls from '../../../services/useUrls'

import styles from '../../../styles/CallToActionTwoColumns.module.less'

const RightColumn: FC = () => {
  const { t } = useTranslation('products')
  const { openRegisterModal } = useLoginModal()
  const { user } = useAuth()
  const { pushT } = useUrls()

  return (
    <Panel className={styles['right-column']}>
      <div className="h2">
        {t('landing:Create your company account')}
      </div>
      <p>
        <SafeTranslate
          i18nKey="landing:Coordinate purchasing with your team and reduce time spent ordering with Smart Catalogs"
          components={{
            span: <span className="font-semibold" />,
          }}
        />
      </p>
      <CTACard
        buttonLabel={t('landing:Create your smart catalog')}
        onClick={() => (user ? pushT('/my-catalogs') : openRegisterModal())}
        avatarUrl="/icons/user-icon.svg"
      />
    </Panel>
  )
}

export default RightColumn
