import React, { FC } from 'react'
import { Col, FlexboxGrid } from 'rsuite'

import RightColumn from './RightColumn'
import LeftColumn from './LeftColumn'

import styles from '../../../styles/CallToActionTwoColumns.module.less'

interface CallToActionTwoColumnsProps {
  className?: string
  title?: string
}

// TODO: Refactor to use composition or pass react nodes to columns to make reusable
// and refactor, replace and remove CatalogBottomSection component
const CallToActionTwoColumns: FC<CallToActionTwoColumnsProps> = (props) => {
  const { className, title } = props

  const columnProps = {
    as: Col,
    xs: 24,
    lg: 12,
  }

  return (
    <section className={`${styles['section-container']} ${className}`}>
      {title && <h2>{title}</h2>}
      <FlexboxGrid align="middle">
        <FlexboxGrid.Item {...columnProps}>
          <LeftColumn />
        </FlexboxGrid.Item>
        <FlexboxGrid.Item {...columnProps}>
          <RightColumn />
        </FlexboxGrid.Item>
      </FlexboxGrid>
    </section>
  )
}

export default CallToActionTwoColumns
