import { useRouter } from 'next/router'
import useSWR from 'swr'

import {
  RecommendationPlacement,
  DEFAULT_RECOMMENDED_PRODUCT_TO_FETCH,
  DEFAULT_VISITOR_ID,
} from '../utils/constants'
import { IResponseDocument } from '../utils/types/aimeosApi'
import { Product } from '../utils/types/Product'
import { getCookie } from '../utils/cookies'
import { useAuth } from './useAuth'
import { parseResponse } from './useApiHelpers'
import { getCountryAndLocaleStrings } from '../utils/locales'

const apiUrl = process.env.NEXT_PUBLIC_API_URL

interface RecommendationProps {
  placement?: RecommendationPlacement
  pageSize?: number
  productCode?: string
  categoryFilter?: string
}

const useProductRecommendations = (props: RecommendationProps) => {
  const {
    placement = RecommendationPlacement.homePage,
    pageSize = DEFAULT_RECOMMENDED_PRODUCT_TO_FETCH,
    productCode,
    categoryFilter,
  } = props

  const { user } = useAuth()
  const { locale: countryAndLocale } = useRouter()
  const { country, locale } = getCountryAndLocaleStrings(countryAndLocale)

  const gaCookie = getCookie('_ga')
  const userPseudoId = gaCookie?.[1]?.substring(6) || DEFAULT_VISITOR_ID
  const userEmail = user?.email ? `&email=${user.email}` : ''
  const sku = productCode ? `&productCode=${productCode}` : ''
  const visitorId = userPseudoId ? `&visitorId=${userPseudoId}` : ''
  const catFilter = categoryFilter ? `&categoryFilter=${categoryFilter}` : ''
  const query = `?country=${country}&locale=${locale}&site=default&placement=${placement}&pageSize=${pageSize}${userEmail}${sku}${catFilter}${visitorId}`
  const url = `${apiUrl}/api/v1/product-recommendations${query}`

  const {
    data,
  } = useSWR<IResponseDocument>(url)
  const { data: products } = parseResponse<Product>(data)

  return {
    recommendedProducts: products,
  }
}

export default useProductRecommendations
