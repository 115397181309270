import { FC } from 'react'
import { useRouter } from 'next/router'

import Img from '../Img'
import SafeTranslate from '../common/SafeTranslate'
import { getCountryAndLocaleStrings } from '../../utils/locales'

import styles from '../../styles/LandingPage/TrustedByLogos.module.less'

const TrustedByLogos: FC = () => {
  const { locale } = useRouter()
  const { country } = getCountryAndLocaleStrings(locale)

  // 3 images with 3 logos batched in each image. An image acts as a row on smaller devices.
  const trustLogos = [`trust-logos-1-${country}.webp`, `trust-logos-2-${country}.webp`, `trust-logos-3-${country}.webp`]
    .map((src) => (
      <Img
        key={src}
        src={src}
        width={400}
        height={40}
        alt="Company logos which trust Droppe for their procurement needs"
      />
    ))

  return (
    <section className={styles['trusted-logos-container']}>
      <p>
        <SafeTranslate
          i18nKey="landing:Trusted by 1000s Operators and Enterprises"
          components={{
            span: <span className="highlight-text" />,
          }}
        />
      </p>
      <div className={styles['trust-logos']}>
        {trustLogos}
      </div>

    </section>
  )
}

export default TrustedByLogos
