import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'
import { Button, Panel, Stack } from 'rsuite'
import Link from 'next/link'

import useUrls from '../../../services/useUrls'

import buttonStyles from '../../../styles/CustomButtons.module.less'
import styles from '../../../styles/CallToActionTwoColumns.module.less'

const LeftColumn: FC = () => {
  const { t } = useTranslation('products')
  const { urlT } = useUrls()

  return (
    <Panel
      bordered
      className={`${styles['left-column']} align-middle text-center`}
    >
      <Stack
        spacing={15}
        direction="column"
      >
        <div className={`h2 ${styles.headline}`}>{t('landing:Start sourcing')}</div>
        <p>
          {t('landing:Make better purchase decisions and secure better terms hassle-free')}
        </p>
        <Link href={urlT('/products')}>
          <Button
            appearance="primary"
            className={`${
              buttonStyles['custom-button']}
              ${buttonStyles['button-primary']}
              ${buttonStyles['button-caret']}
              `}
            size="lg"
          >
            {t('landing:Source products')}
          </Button>
        </Link>
      </Stack>
    </Panel>
  )
}

export default LeftColumn
