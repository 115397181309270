import React, { FC } from 'react'
import { Col, FlexboxGrid, Placeholder } from 'rsuite'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'

import useUrls from '../../services/useUrls'
import { useApi } from '../../services/useApi'
import ArrowLink from '../ArrowLink'
import ProductsWithCarousel from '../ProductsWithCarousel'
import { Product } from '../../utils/types/Product'
import useCategoryDataStructure from '../hooks/useCategoryDataStructure'
import { removeHomeCategory } from '../../lib/category'
import { RecommendationPlacement, ProductBaseIncludes, ProductListFieldsQuery, ResourceTag } from '../../utils/constants'
import { createUrlParams } from '../../lib/params'
import useProductRecommendations from '../../services/useProductRecommendations'
import useIsOnMobile from '../../services/useIsOnMobile'

import styles from '../../styles/CategorySpecificCarousel.module.less'
import landingStyles from '../../styles/LandingPage/LandingPage.module.less'

interface FeaturedProductsProps{
  label: string
  newProducts?: boolean
  hasRecommendedProducts?: boolean
  productCode?: string
  placement?: RecommendationPlacement
  categoryFilter?: string
  className?: string
  callToAction?: string
}

const FeaturedProducts: FC<FeaturedProductsProps> = (props) => {
  const {
    label,
    newProducts,
    hasRecommendedProducts = false,
    productCode,
    placement = RecommendationPlacement.homePage,
    categoryFilter,
    className = '',
    callToAction = 'common:Browse products',
  } = props

  const { urlT } = useUrls()
  const isOnMobile = useIsOnMobile()
  const { getResource } = useApi()
  const { t } = useTranslation('landing')
  const { pathname } = useRouter()
  const { categories } = useCategoryDataStructure()

  const { recommendedProducts } = useProductRecommendations({
    placement,
    pageSize: 20,
    productCode,
    categoryFilter,
  })

  const mainCategoryIds = removeHomeCategory(categories).map((cat) => cat.id) || []
  const mainCategoriesQuery = createUrlParams({
    filter: {
      f_catid: mainCategoryIds,
    },
    ...(newProducts && { sort: '-ctime' }),
  })
  const query = `include=${ProductBaseIncludes.join(',')}${mainCategoriesQuery}&page[limit]=10${ProductListFieldsQuery}`

  const { data: products } = getResource<Product>(ResourceTag.product, query)

  const browseProductsUrl = urlT('/products')

  const browseProductsLinkTarget = pathname.startsWith('/cart') ? '_blank' : undefined

  return (
    <div className={`${landingStyles['favourite-products']} ${className}`}>
      <div className={styles['product-carousel']}>
        <FlexboxGrid
          justify="space-between"
          className={`${styles['zIndex-1']} align-baseline margin-bottom-spacer-double`}
        >
          <FlexboxGrid.Item className="flex-shrink-1">
            <h4 className="margin-bottom-zero">{label}</h4>
          </FlexboxGrid.Item>
          <FlexboxGrid.Item
            as={Col}
            xsHidden
            className={landingStyles['browse-products']}
          >
            <ArrowLink
              target={browseProductsLinkTarget}
              href={browseProductsUrl}
              text={t(callToAction)}
              bold
            />
          </FlexboxGrid.Item>
        </FlexboxGrid>
        {hasRecommendedProducts && recommendedProducts.length === 0 ? (
          <FlexboxGrid className={styles['skeleton-wrapper']}>
            {Array.from({ length: isOnMobile ? 2 : 5 }, (_, i) => (
              <Placeholder.Graph
                active
                key={i}
                className={styles['skeleton-product-card']}
              />
            ))}
          </FlexboxGrid>
        )
          : (
            <ProductsWithCarousel
              products={hasRecommendedProducts ? recommendedProducts : products}
              browseProductsUrl={browseProductsUrl}
            />
          )}
      </div>
    </div>
  )
}

export default FeaturedProducts
