import { FC } from 'react'
import { FlexboxGrid, Panel } from 'rsuite'
import Image from 'next/legacy/image'
import useTranslation from 'next-translate/useTranslation'
import { useRouter } from 'next/router'
import Link from 'next/link'

import { useApi } from '../../services/useApi'
import { Supplier } from '../../utils/types/Product'
import { imageLoader } from '../../utils/images'
import Carousel from '../Layouts/Carousel'
import FallBackCategoryImg from '../Graphics/FallBackCategoryImg'
import useUrls from '../../services/useUrls'
import useMounted from '../../services/useMounted'
import { ResourceTag, SUPPLIERS_TO_FETCH_ON_CLIENT } from '../../utils/constants'
import { getSupplierDeliveryDetails } from '../../lib/service'
import { getCountryAndLocaleStrings } from '../../utils/locales'

import styles from '../../styles/LandingPage/SupplierCirclesCarousel.module.less'

const SupplierCirclesCarousel: FC = () => {
  const { getResource } = useApi()
  const { urlT } = useUrls()
  const { t } = useTranslation('landing')
  const { locale } = useRouter()
  const { country } = getCountryAndLocaleStrings(locale)

  const isMounted = useMounted()

  const { data: suppliersUnfiltered } = getResource<Supplier>(ResourceTag.supplier,
    `include=media,text,supplier/address,supplier/service&sort=supplier.address.position&page[limit]=${SUPPLIERS_TO_FETCH_ON_CLIENT}`)

  const suppliers = suppliersUnfiltered.filter((supplier) => (
    getSupplierDeliveryDetails(supplier, country).isAvailable
  ))

  // To fix some warning generated for using rsuite paragraph component before component loads
  if (!isMounted) {
    return <div />
  }

  return (
    <div className={styles['supplier-circles-carousel-wrapper']}>
      <h2 className="h4">{t('landing:Over 200 suppliers with EU standards')}</h2>
      <FlexboxGrid className={styles['supplier-circles-carousel']}>
        <Carousel
          hideArrowsFade={suppliers.length < 11}
          hideArrows={suppliers.length < 11}
        >
          {suppliers.map((supplier) => {
            const supplierName = supplier['supplier.label']
            const supplierCode = supplier['supplier.code']
            const imageAlt = `${supplierName} logo`

            return (
              <div
                key={supplierCode}
                className={styles['carousel-card']}
              >
                <Link href={urlT(`/supplier/${supplierCode}`)}>
                  <Panel
                    bordered
                    bodyFill
                  >
                    <FlexboxGrid.Item className={styles.body}>
                      <FlexboxGrid justify="center">
                        {supplier.media?.[0]['media.url']
                          ? (
                            <div>
                              <Image
                                loader={imageLoader}
                                src={imageLoader(supplier.media[0]['media.url'])}
                                alt={imageAlt}
                                layout="fill"
                              />
                            </div>
                          ) : (
                            <FallBackCategoryImg height="80" />
                          )}
                      </FlexboxGrid>
                    </FlexboxGrid.Item>
                  </Panel>
                </Link>
              </div>
            )
          })}
        </Carousel>
      </FlexboxGrid>
    </div>
  )
}

export default SupplierCirclesCarousel
