import { FC } from 'react'
import useTranslation from 'next-translate/useTranslation'

import FeaturesBanner from './FeaturesBanner'
import BackToTopButton from '../Catalog/BackToTopButton'
import SuppliersCarousel from './SuppliersCarousel/SuppliersCarousel'
import FeaturedProducts from './FeaturedProducts'
import HeroSection from './HeroSection'
import { useAuth } from '../../services/useAuth'
import TrustedByLogos from './TrustedByLogos'
import SupplierCirclesCarousel from './SupplierCirclesCarousel'
import FeaturesBenefitsSection from './FeaturesBenefitsSection'
import ContentAndFeaturedProducts from './ContentAndFeaturedProducts'
import CallToActionTwoColumns from './CallToActionTwoColumns/CallToActionTwoColumns'

import styles from '../../styles/LandingPage/LandingPage.module.less'

const LandingPage: FC = () => {
  const { t } = useTranslation('landing')
  const { user } = useAuth()

  return (
    <div className={`${styles['landing-page-main']} ${styles['with-hero-section-container']}`}>
      <BackToTopButton />
      <HeroSection />
      {user && (
      <FeaturedProducts
        className={styles['recommended-products-section']}
        hasRecommendedProducts
        label={t('landing:Recommended products')}
      />
      )}
      <TrustedByLogos />
      <FeaturesBanner />
      <SupplierCirclesCarousel />
      <FeaturesBenefitsSection />
      <SuppliersCarousel />
      <ContentAndFeaturedProducts />
      <CallToActionTwoColumns title={t('landing:Ready to redefine the way you buy')} />
    </div>
  )
}

export default LandingPage
